:root {
  --body_background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  --body_background_rgba: linear-gradient(90deg, rgba(28, 27, 27, 0.8) 0%, rgba(26, 23, 23,0.8) 100%);
  --body_text: white;
  --body_color: rgb(200, 0, 0);
  --cards_color: linear-gradient(90deg, rgb(68, 67, 67) 0%, rgb(66, 63, 63) 100%);
  --form_input: grey;
  --nav_menu_active: rgb(36, 34, 34);
}

[theme="light"] {
  --body_background: linear-gradient(90deg, rgb(227, 228, 228) 0%, rgb(229, 232, 232) 100%);
  --body_background_rgba: linear-gradient(90deg, rgba(227, 228, 228, 0.9) 0%, rgba(229, 232, 232, 0.9) 100%);
  --body_text: black;
  --body_color: rgb(200, 0, 0);
  --cards_color: linear-gradient(90deg, rgb(187, 188, 188) 0%, rgb(189, 192, 192) 100%);
  --form_input: rgb(189,192,192);
  --nav_menu_active: rgb(219, 221, 221);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.navbar {
    background: var(--body_background);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: var(--body_text);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    /*Number of menus*/
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: var(--body_text);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    color:  var(--body_color);
    border-bottom: 4px solid var(--body_color);
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: var(--body_text);
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  /*.nav-checkbox{
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 40px;
  }*/
.darkmode-button{
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 100%;
}
.darkmode-button label{
  color:white;
}

  .nav-checkbox{
    height: 40px;
    width: 80px;
    background: #222;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 160px;
    box-shadow: inset 0 2px 15px rgba(0, 0, 0, 0.1),
                inset 0 2px 2px rgba(0, 0, 0, 0.1),
                inset 0 -1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    outline: none;
    cursor: pointer;
    transition: 0.5s;
    }

    .nav-checkbox::before{
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      background: linear-gradient(to bottom,#444,#222); 
      border-radius: 40px;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      transition: 0.5s;
      box-shadow: 0 2px 10px rgba(0,0,0,0.5),
                  inset 1px 1px rgba(255,255,255,0.2),
                  inset -1px 1px rgba(255,255,255,0.2);
  }
  
  .nav-checkbox:checked{
      background: #fff; 
      box-shadow: inset 0 1px 15px rgba(0,0,0,0.1),
                  inset 0 1px 2px rgba(0,0,0,0.1),
                  inset 0 -1px 1px rgba(0,0,0,0.05);
  }
  
  .nav-checkbox:checked::before{
      left: 40px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.1),
                  inset 1px 1px rgba(255,255,255,1),
                  inset -1px 1px rgba(255,255,255,1);
      /*background: linear-gradient(to bottom,#eaeaea,#f9f9f9);*/   
      background: linear-gradient(to bottom,#ffaf02,#fddd23);         
  }
  
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: unset;
    }
  
    .nav-menu.active {
      background: var(--nav_menu_active);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: var(--body_color);
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      left: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      color: var(--body_text);
      fill: var(--body_text);
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    /*.fa-times {
      color: var(--body_text);
      font-size: 2rem;
    }*/
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: var(--body_text);
      padding: 14px 20px;
      border: 1px solid var(--body_text);
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: var(--body_text);
      color: var(--nav_menu_active);
      transition: 250ms;
    }
    .darkmode-button{
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }  
  }
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .btn--form_submit {
    background-color: transparent;
    color: black;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: black;
  }
  .btn--form_submit:hover{
    background:greenyellow;
    transition: all 0.3s ease-out;
    transition: 250ms;
  }
  .btn--form_reset {
    background-color: transparent;
    color: black;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: black;
  }
  .btn--form_reset:hover{
    background: red;
    transition: all 0.3s ease-out;
    transition: 250ms;
  }

  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--outline:hover,
  .btn--primary:hover {
    transition: all 0.3s ease-out;
    background: white;
    color: #242424;
    transition: 250ms;
  }

video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position:fixed;
    z-index: -1;
}
.hero-container {
    /*background-color:navy;
    background-image: url('../images/pink-leather-2001739.jpg');*/
    /*IDE JÖN A HÁTTÉR*/
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    text-align: center;
}

.hero-container > h1 {
    color: white;
    font-size: 80px;
}

.hero-container > p {
    margin-top: 8px;
    margin-inline: 20%;
    color: white;
    font-size: 24px; 
}

.hero-btns {
    margin-top: 32px;    
}

.hero-btns .btn {
    margin: 6px;   
}
.contact-btns {
    display: flex;
    justify-content: center;
}
.contact-btns a {
    line-height: 0; 
    font-size: 0;
    color: transparent;
}
/*.contact-btns i {
    text-decoration: none;
    font-size: 32px;
    color: white;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}*/
.contact-icon{
    color: white;
    fill: white;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}
.contact-btns > a:hover .contact-icon {
    color: var(--body_color);
    fill: var(--body_color);
    transition: all 0.2s ease-out;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        color: white;
        font-size: 40px;
        margin-top: -150px;
    }
    .hero-container > p {
        margin-inline: 10%;
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        color: white;
        font-size: 40px;
        margin-top: -100px;
    }
    .hero-container > p {
        margin-inline: 10%;
        font-size: 16px;
    }
    .btn-mobile{
        display: block;
        text-decoration: none;
    }

    .btn{
        width: 100%;
    }
}

.dtf-container {
   background:var(--body_background);
}
.dtf-elements {
    padding: 4rem;
    padding: 30px;
    display:grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 20px;
    width: 80%;
    margin: 0 auto;
    /*justify-content: center;*/
}
.dtf-text{
    margin-top: 100px;
    width: 100%;
}
.dtf-text > h2 {
    color: var(--body_text);
    font-size: 32px;
    margin-top: -100px;
}

.dtf-text > p {
    margin-top: 8px;
    color: var(--body_text);
    font-size: 20px; 
    line-height: 28px;
}
.video_wrap{
    margin-top: 8px;
}
iframe{
    width: 600px;
    aspect-ratio: 16/9;
}

@media screen and (max-width: 1400px) {
    iframe{
        width: 500px;
        aspect-ratio: 16/9;
    }
}
@media screen and (max-width: 1250px) {
    iframe{
        width: 450px;
        aspect-ratio: 16/9;
    }
}

@media screen and (max-width: 960px) {
    .dtf-elements{
        display:block;
        border-radius: 0px;
        margin: 0, auto;
        align-items: center;
        width: 95%;
    }
    .dtf-text{
        float: none;
    }
    .dtf-text > h2 {
        font-size: 24px;
    }
    .dtf-text > p {
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
    }
    iframe{
        margin: auto;
        display: block;
        width: 100%;
        aspect-ratio: 16/9;
    }
}

.cards {
  padding: 4rem;
  background: var(--body_background);
}

h2 {
  text-align: center;
  color: var(--body_text)
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  box-shadow: 0 6px 20px rgba(255, 255, 255, 0.017);
  -webkit-filter: drop-shadow(0 6px 20px rgba(255, 255, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(255, 255, 255, 0.017));
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 20px;
}

.cards__item:hover .cards__item__img{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 250ms;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  color: var(--body_text);
  background: var(--cards_color);
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.cards__item__img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display:flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 20px;
  text-align: center;
}

.cards__item__text {
  color: var(--body_texts);
  font-size: 18px;
  line-height: 24px;
}
.cards__item__description {
  color: var(--body_texts);
  font-size: 12px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  h2 {
    font-size: 24px;
  }
  .cards{
    padding: 1rem;
  }
  .cards__item {
    margin-bottom: 2rem;
  }
  .cards__item__info {
    padding: 20px 15px 20px;
    text-align: center;
  }
}
.form-container{
    background: var(--body_background); 
}
.form-elements{
    color: var(--body_text);
    /*margin-top: 32px;*/
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 20%;
    padding-right: 20%;
    border-radius: 20px;
    text-align: center;
    /*justify-content: center;*/
    /*object-fit: contain;*/
}
.form-ordertype-container{
    display: grid;
    grid-template-columns: repeat(2, auto);
}
.form-ordertype-option{
    display: flex;
}
.form-ordertype-option label:has(>input[type="radio"]:checked ){
    background-color: var(--body_color);
}
.form-ordertype-option label{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid var(--body_text);
    cursor: pointer;
    /*transition: all 0.3s ease-out;*/
}
.form-container label{
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}
.form-description{
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
}
.form-container h2{
    color: var(--body_color);
    margin-bottom: 32px;
}
.final_price_h3{
    color: rgb(0, 200, 0);
}
.final_price_p{
    color: rgb(0, 200, 0);
    padding-bottom: 16px;
}

.form-container input, .form-container select, .form-container textarea{
    width: 100%;
    padding: 6px 10px;
    border-radius: 20pc;
    background-color: var(--form_input);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
    border: 1px solid var(--body_text);
    box-sizing: border-box;
    display: block;
}
input[type="file"]{
    display: none;
}
.form-fileupload-button{
    margin-inline: 30%;
    cursor: pointer;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 18px;
    color: var(--body_text);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: var(--body_text);
}
.form-fileupload-button:hover{
    background: var(--body_color);
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.form-fileupload-text{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--body_color);
}
.form-company-option{
    display: flex;
    justify-content: center;
}
.form-company-container label{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid var(--body_text);
    cursor: pointer;
}
.form-company-container input[type="checkbox"]{
    display: none;
}
.form-company-container label:has(>input[type="checkbox"]:checked ){
    background-color: var(--body_color);
}
.form-company-container label:before{
    content: "";
    height: 15px;
    width: 15px;
    border: 2px solid var(--body_text);
    border-radius: 50%;
    margin-right: 20px;
}
.form-company-container label:has(>input[type="checkbox"]:checked ):before{
    height: 10px;
    width: 10px;
    border: 5px solid var(--body_text);
    background-color: var(--body_color);
}
.form-address-container{
    display: flex;
    width: 100%;
    justify-content: center;
}

.form-address-items{
    width: 100%;
    display: flex;
    flex-direction:column;
    padding-right: 20px;
    padding-bottom: 32px;
    text-align: left;
    box-sizing: border-box;
    align-items: center;
}
.form-shipping-container{
    display: grid;
    grid-template-columns: repeat(2, auto);
}
.form-shipping-option{
    display: flex;
}
input[type="radio"]{
    display: none;
}
.form-shipping-option label:has(>input[type="radio"]:checked ){
    background-color: var(--body_color);
}
.form-shipping-option label{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid var(--body_text);
    cursor: pointer;
    /*transition: all 0.3s ease-out;*/
}
.form-shipping-option label:before{
    content: "";
    height: 15px;
    width: 15px;
    border: 2px solid var(--body_text);
    border-radius: 50%;
    margin-right: 20px;
}
.form-shipping-option label:has(>input[type="radio"]:checked ):before{
    height: 10px;
    width: 10px;
    border: 5px solid var(--body_text);
    background-color: var(--body_color);
}
.form-message{
    resize: none;
}
.select_options{
    color: var(--body_background);
}
.select_option{
    color: var(--body_background);
}

::-webkit-input-placeholder{
color: var(--body_background);
}

::placeholder{
color: var(--body_background);
}

.form-btns-submit, .form-btns-reset{
    background-color: transparent;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 18px;
    color: var(--body_text);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: var(--body_text);
    cursor: pointer;
}
.form-btns-submit:hover{
    background:rgb(0, 200, 0);
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.form-btns-reset:hover{
    background:var(--body_color);
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.progress-container{
    padding-bottom: 30px;
}
@media screen and (max-width: 1450px) {
    .form-ordertype-container{
        display:block;
    }
    .form-ordertype-option{
        justify-content: center;
    }
    .form-company-container{
        display: block;
    }
    .form-company-option{
        justify-content: center;
    }
}
@media screen and (max-width: 960px) {
    .form-elements{
        margin-left: 5%;
        margin-right: 5%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .form-container label{
        font-size: 16px;
        line-height: 24px;
    }
    input[type="file"]{
        text-align:center;
        margin-inline: 5%;
        background-color: transparent;
        border-color: transparent;
        cursor: pointer;
    }
    .form-address-container{
        width: 100%;
        display: block;
        justify-content: center;
    }
    
    .form-address-items{
        display:block;
        padding-top: 32px;
        margin-right: 0px;
        margin-left: 0px;
        padding-bottom: 0px;
        text-align: center;
        box-sizing: border-box;
        align-items: center;
    }
    .form-shipping-container{
        display:block;
    }
    .form-shipping-option{
        justify-content: center;
    }
    .form-shipping-option label:before{
        content: "";
        height: 15px;
        width: 15px;
        border: 2px solid var(--body_text);
        border-radius: 50%;
        margin-right: 5px;
    }
    .form-shipping-option label:has(>input[type="radio"]:checked ):before{
        height: 10px;
        width: 10px;
        border: 5px solid var(--body_text);
        background-color: var(--body_color);
    }
    .form-btns-submit:hover{
        background: transparent;
        transition: all 0.3s ease-out;
        transition: 250ms;
    }
}



.footer-container{
    background: var(--body_background);
    color: var(--body_text);
}
.footer-contact{
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 16px;
    background: var(--body_color);
    text-align: center;
}

.footer-contact h2 a{
    text-decoration: none;
    color: var(--body_text);
}

.footer-contact h2 a :hover{
    color: var(--body_background);
    transition: all 0.2s ease-out;
}

.footer-links{
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-left: 150px;
    margin-right: 150px;
    text-align: left;
    width: 250px;
    box-sizing: border-box;
}

.footer-items a {
    text-decoration: none;
    color: var(--body_text);
    margin-top: 16px;
}
.footer-items a:hover{
    color: var(--body_color);
    border-bottom: 2px solid var(--body_color);
    transition: all 0.2s ease-out;
}

.footer-socialmedia{
    display: flex;
    justify-content: center;
}
.footer-socialmedia a {
    line-height: 0; 
    font-size: 0;
    color: transparent; 
}
/*.footer-socialmedia i {
    text-decoration: none;
    font-size: 24px;
    color: var(--body_text);
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}*/
.social-icon{
    color: var(--body_text);
    fill: var(--body_text);
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}
.footer-socialmedia > a:hover .social-icon{
    color: var(--body_color);
    fill: var(--body_color);
    transition: all 0.2s ease-out;
}
@media screen and (max-width: 960px) {
    .footer-contact{
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .footer-contact h2{
        font-size: 16px;
    }
    .footer-links{
        display: block;
        justify-content:left;  
    }
    .footer-items{
        margin-inline: 20px ;
    }
}
