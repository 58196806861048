.form-container{
    background: var(--body_background); 
}
.form-elements{
    color: var(--body_text);
    /*margin-top: 32px;*/
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 20%;
    padding-right: 20%;
    border-radius: 20px;
    text-align: center;
    /*justify-content: center;*/
    /*object-fit: contain;*/
}
.form-ordertype-container{
    display: grid;
    grid-template-columns: repeat(2, auto);
}
.form-ordertype-option{
    display: flex;
}
.form-ordertype-option label:has(>input[type="radio"]:checked ){
    background-color: var(--body_color);
}
.form-ordertype-option label{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid var(--body_text);
    cursor: pointer;
    /*transition: all 0.3s ease-out;*/
}
.form-container label{
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}
.form-description{
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
}
.form-container h2{
    color: var(--body_color);
    margin-bottom: 32px;
}
.final_price_h3{
    color: rgb(0, 200, 0);
}
.final_price_p{
    color: rgb(0, 200, 0);
    padding-bottom: 16px;
}

.form-container input, .form-container select, .form-container textarea{
    width: 100%;
    padding: 6px 10px;
    border-radius: 20pc;
    background-color: var(--form_input);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
    border: 1px solid var(--body_text);
    box-sizing: border-box;
    display: block;
}
input[type="file"]{
    display: none;
}
.form-fileupload-button{
    margin-inline: 30%;
    cursor: pointer;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 18px;
    color: var(--body_text);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: var(--body_text);
}
.form-fileupload-button:hover{
    background: var(--body_color);
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.form-fileupload-text{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--body_color);
}
.form-company-option{
    display: flex;
    justify-content: center;
}
.form-company-container label{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid var(--body_text);
    cursor: pointer;
}
.form-company-container input[type="checkbox"]{
    display: none;
}
.form-company-container label:has(>input[type="checkbox"]:checked ){
    background-color: var(--body_color);
}
.form-company-container label:before{
    content: "";
    height: 15px;
    width: 15px;
    border: 2px solid var(--body_text);
    border-radius: 50%;
    margin-right: 20px;
}
.form-company-container label:has(>input[type="checkbox"]:checked ):before{
    height: 10px;
    width: 10px;
    border: 5px solid var(--body_text);
    background-color: var(--body_color);
}
.form-address-container{
    display: flex;
    width: 100%;
    justify-content: center;
}

.form-address-items{
    width: 100%;
    display: flex;
    flex-direction:column;
    padding-right: 20px;
    padding-bottom: 32px;
    text-align: left;
    box-sizing: border-box;
    align-items: center;
}
.form-shipping-container{
    display: grid;
    grid-template-columns: repeat(2, auto);
}
.form-shipping-option{
    display: flex;
}
input[type="radio"]{
    display: none;
}
.form-shipping-option label:has(>input[type="radio"]:checked ){
    background-color: var(--body_color);
}
.form-shipping-option label{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 8px 20px;
    border: 1px solid var(--body_text);
    cursor: pointer;
    /*transition: all 0.3s ease-out;*/
}
.form-shipping-option label:before{
    content: "";
    height: 15px;
    width: 15px;
    border: 2px solid var(--body_text);
    border-radius: 50%;
    margin-right: 20px;
}
.form-shipping-option label:has(>input[type="radio"]:checked ):before{
    height: 10px;
    width: 10px;
    border: 5px solid var(--body_text);
    background-color: var(--body_color);
}
.form-message{
    resize: none;
}
.select_options{
    color: var(--body_background);
}
.select_option{
    color: var(--body_background);
}

::placeholder{
color: var(--body_background);
}

.form-btns-submit, .form-btns-reset{
    background-color: transparent;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 18px;
    color: var(--body_text);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: var(--body_text);
    cursor: pointer;
}
.form-btns-submit:hover{
    background:rgb(0, 200, 0);
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.form-btns-reset:hover{
    background:var(--body_color);
    transition: all 0.3s ease-out;
    transition: 250ms;
}
.progress-container{
    padding-bottom: 30px;
}
@media screen and (max-width: 1450px) {
    .form-ordertype-container{
        display:block;
    }
    .form-ordertype-option{
        justify-content: center;
    }
    .form-company-container{
        display: block;
    }
    .form-company-option{
        justify-content: center;
    }
}
@media screen and (max-width: 960px) {
    .form-elements{
        margin-left: 5%;
        margin-right: 5%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .form-container label{
        font-size: 16px;
        line-height: 24px;
    }
    input[type="file"]{
        text-align:center;
        margin-inline: 5%;
        background-color: transparent;
        border-color: transparent;
        cursor: pointer;
    }
    .form-address-container{
        width: 100%;
        display: block;
        justify-content: center;
    }
    
    .form-address-items{
        display:block;
        padding-top: 32px;
        margin-right: 0px;
        margin-left: 0px;
        padding-bottom: 0px;
        text-align: center;
        box-sizing: border-box;
        align-items: center;
    }
    .form-shipping-container{
        display:block;
    }
    .form-shipping-option{
        justify-content: center;
    }
    .form-shipping-option label:before{
        content: "";
        height: 15px;
        width: 15px;
        border: 2px solid var(--body_text);
        border-radius: 50%;
        margin-right: 5px;
    }
    .form-shipping-option label:has(>input[type="radio"]:checked ):before{
        height: 10px;
        width: 10px;
        border: 5px solid var(--body_text);
        background-color: var(--body_color);
    }
    .form-btns-submit:hover{
        background: transparent;
        transition: all 0.3s ease-out;
        transition: 250ms;
    }
}


