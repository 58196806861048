.navbar {
    background: var(--body_background);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: var(--body_text);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    /*Number of menus*/
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: var(--body_text);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    color:  var(--body_color);
    border-bottom: 4px solid var(--body_color);
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: var(--body_text);
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  /*.nav-checkbox{
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 40px;
  }*/
.darkmode-button{
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 100%;
}
.darkmode-button label{
  color:white;
}

  .nav-checkbox{
    height: 40px;
    width: 80px;
    background: #222;
    appearance: none;
    border-radius: 160px;
    box-shadow: inset 0 2px 15px rgba(0, 0, 0, 0.1),
                inset 0 2px 2px rgba(0, 0, 0, 0.1),
                inset 0 -1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    outline: none;
    cursor: pointer;
    transition: 0.5s;
    }

    .nav-checkbox::before{
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      background: linear-gradient(to bottom,#444,#222); 
      border-radius: 40px;
      transform: scale(0.9);
      transition: 0.5s;
      box-shadow: 0 2px 10px rgba(0,0,0,0.5),
                  inset 1px 1px rgba(255,255,255,0.2),
                  inset -1px 1px rgba(255,255,255,0.2);
  }
  
  .nav-checkbox:checked{
      background: #fff; 
      box-shadow: inset 0 1px 15px rgba(0,0,0,0.1),
                  inset 0 1px 2px rgba(0,0,0,0.1),
                  inset 0 -1px 1px rgba(0,0,0,0.05);
  }
  
  .nav-checkbox:checked::before{
      left: 40px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.1),
                  inset 1px 1px rgba(255,255,255,1),
                  inset -1px 1px rgba(255,255,255,1);
      /*background: linear-gradient(to bottom,#eaeaea,#f9f9f9);*/   
      background: linear-gradient(to bottom,#ffaf02,#fddd23);         
  }
  
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: unset;
    }
  
    .nav-menu.active {
      background: var(--nav_menu_active);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: var(--body_color);
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      left: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      color: var(--body_text);
      fill: var(--body_text);
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    /*.fa-times {
      color: var(--body_text);
      font-size: 2rem;
    }*/
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: var(--body_text);
      padding: 14px 20px;
      border: 1px solid var(--body_text);
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: var(--body_text);
      color: var(--nav_menu_active);
      transition: 250ms;
    }
    .darkmode-button{
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }  
  }