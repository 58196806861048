.dtf-container {
   background:var(--body_background);
}
.dtf-elements {
    padding: 4rem;
    padding: 30px;
    display:grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 20px;
    width: 80%;
    margin: 0 auto;
    /*justify-content: center;*/
}
.dtf-text{
    margin-top: 100px;
    width: 100%;
}
.dtf-text > h2 {
    color: var(--body_text);
    font-size: 32px;
    margin-top: -100px;
}

.dtf-text > p {
    margin-top: 8px;
    color: var(--body_text);
    font-size: 20px; 
    line-height: 28px;
}
.video_wrap{
    margin-top: 8px;
}
iframe{
    width: 600px;
    aspect-ratio: 16/9;
}

@media screen and (max-width: 1400px) {
    iframe{
        width: 500px;
        aspect-ratio: 16/9;
    }
}
@media screen and (max-width: 1250px) {
    iframe{
        width: 450px;
        aspect-ratio: 16/9;
    }
}

@media screen and (max-width: 960px) {
    .dtf-elements{
        display:block;
        border-radius: 0px;
        margin: 0, auto;
        align-items: center;
        width: 95%;
    }
    .dtf-text{
        float: none;
    }
    .dtf-text > h2 {
        font-size: 24px;
    }
    .dtf-text > p {
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
    }
    iframe{
        margin: auto;
        display: block;
        width: 100%;
        aspect-ratio: 16/9;
    }
}
