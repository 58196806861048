.cards {
  padding: 4rem;
  background: var(--body_background);
}

h2 {
  text-align: center;
  color: var(--body_text)
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  box-shadow: 0 6px 20px rgba(255, 255, 255, 0.017);
  -webkit-filter: drop-shadow(0 6px 20px rgba(255, 255, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(255, 255, 255, 0.017));
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 20px;
}

.cards__item:hover .cards__item__img{
  transform: scale(1.1);
  transition: 250ms;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  color: var(--body_text);
  background: var(--cards_color);
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display:flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 20px;
  text-align: center;
}

.cards__item__text {
  color: var(--body_texts);
  font-size: 18px;
  line-height: 24px;
}
.cards__item__description {
  color: var(--body_texts);
  font-size: 12px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  h2 {
    font-size: 24px;
  }
  .cards{
    padding: 1rem;
  }
  .cards__item {
    margin-bottom: 2rem;
  }
  .cards__item__info {
    padding: 20px 15px 20px;
    text-align: center;
  }
}