:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .btn--form_submit {
    background-color: transparent;
    color: black;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: black;
  }
  .btn--form_submit:hover{
    background:greenyellow;
    transition: all 0.3s ease-out;
    transition: 250ms;
  }
  .btn--form_reset {
    background-color: transparent;
    color: black;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: black;
  }
  .btn--form_reset:hover{
    background: red;
    transition: all 0.3s ease-out;
    transition: 250ms;
  }

  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--outline:hover,
  .btn--primary:hover {
    transition: all 0.3s ease-out;
    background: white;
    color: #242424;
    transition: 250ms;
  }
