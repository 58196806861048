.footer-container{
    background: var(--body_background);
    color: var(--body_text);
}
.footer-contact{
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 16px;
    background: var(--body_color);
    text-align: center;
}

.footer-contact h2 a{
    text-decoration: none;
    color: var(--body_text);
}

.footer-contact h2 a :hover{
    color: var(--body_background);
    transition: all 0.2s ease-out;
}

.footer-links{
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-left: 150px;
    margin-right: 150px;
    text-align: left;
    width: 250px;
    box-sizing: border-box;
}

.footer-items a {
    text-decoration: none;
    color: var(--body_text);
    margin-top: 16px;
}
.footer-items a:hover{
    color: var(--body_color);
    border-bottom: 2px solid var(--body_color);
    transition: all 0.2s ease-out;
}

.footer-socialmedia{
    display: flex;
    justify-content: center;
}
.footer-socialmedia a {
    line-height: 0; 
    font-size: 0;
    color: transparent; 
}
/*.footer-socialmedia i {
    text-decoration: none;
    font-size: 24px;
    color: var(--body_text);
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}*/
.social-icon{
    color: var(--body_text);
    fill: var(--body_text);
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}
.footer-socialmedia > a:hover .social-icon{
    color: var(--body_color);
    fill: var(--body_color);
    transition: all 0.2s ease-out;
}
@media screen and (max-width: 960px) {
    .footer-contact{
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .footer-contact h2{
        font-size: 16px;
    }
    .footer-links{
        display: block;
        justify-content:left;  
    }
    .footer-items{
        margin-inline: 20px ;
    }
}