video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position:fixed;
    z-index: -1;
}
.hero-container {
    /*background-color:navy;
    background-image: url('../images/pink-leather-2001739.jpg');*/
    /*IDE JÖN A HÁTTÉR*/
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    text-align: center;
}

.hero-container > h1 {
    color: white;
    font-size: 80px;
}

.hero-container > p {
    margin-top: 8px;
    margin-inline: 20%;
    color: white;
    font-size: 24px; 
}

.hero-btns {
    margin-top: 32px;    
}

.hero-btns .btn {
    margin: 6px;   
}
.contact-btns {
    display: flex;
    justify-content: center;
}
.contact-btns a {
    line-height: 0; 
    font-size: 0;
    color: transparent;
}
/*.contact-btns i {
    text-decoration: none;
    font-size: 32px;
    color: white;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}*/
.contact-icon{
    color: white;
    fill: white;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
}
.contact-btns > a:hover .contact-icon {
    color: var(--body_color);
    fill: var(--body_color);
    transition: all 0.2s ease-out;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        color: white;
        font-size: 40px;
        margin-top: -150px;
    }
    .hero-container > p {
        margin-inline: 10%;
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        color: white;
        font-size: 40px;
        margin-top: -100px;
    }
    .hero-container > p {
        margin-inline: 10%;
        font-size: 16px;
    }
    .btn-mobile{
        display: block;
        text-decoration: none;
    }

    .btn{
        width: 100%;
    }
}
